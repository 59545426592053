// import dependencies
import React from "react";
import {graphql, Link} from "gatsby";
import Image from "gatsby-plugin-sanity-image";

// import components
import Layout from "../components/layout";
import PostBox from "../components/post-box";
//import Pagination from "../components/pagination";
import Section from "../components/section"
import SectionHeader from "../components/section-header";
import SeparatorTopGrey from "../components/separators/separator-top-grey";

// import styles and assets
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../utils/font-awesome";
import * as styles from "./styles/inspiracion.module.scss";

export default function Inspiracion({data, pageContext}) {
    return (
<Layout>
    <Section color={"white"}>
        <div className={`container ${styles.intro}`}>
            <SectionHeader color="turquoise">
                <h1>Inspiración para todas y todos</h1>
                <h4>Descubre todo sobre las STEM con Ingeniosamente. Contenido para niñas, apoderadas, apoderados y docentes.</h4>
            </SectionHeader>
            <div className={`row ${styles.inspPostRow}`}>
                <div className="col-sm-12">
                    <div className={styles.posts}>
                        {data.allSanityPost.nodes.map((node) => (
                            <PostBox key={node.id}>
                                <div className="profilePhoto">
                                    <Image 
                                        {...node.mainImage}
                                        width={320}
                                        height={320}
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                        }}
                                        alt="Imágen de Inspiración para todas" />
                                </div>
                                <div className="profileText">
                                    <h4>{node.title}</h4>
                                    <div className="sep"></div>
                                    <p>{node.excerpt}</p>
                                    <Link to={`/inspiracion/${node.slug.current}`}><FontAwesomeIcon icon={["fas", "arrow-right"]} /></Link>
                                </div>
                            </PostBox>
                        ))}
                    </div>
                </div>
            </div>
            {/* <div className="row">
                <div className="col-12">
                    <Pagination pageContext={pageContext} color="turquoise" />
                </div>
            </div> */}
        </div>
        <SeparatorTopGrey />
    </Section>
</Layout>
    );
}

export const query = graphql`
query InspiracionPageQuery ($skip: Int!, $limit: Int!) {
    allSanityPost (
    filter: {category: {title: {eq: "Inspiración"}}}, 
    sort: {order: DESC, fields: publishedAt}
    skip: $skip,
    limit: $limit) {
        nodes {
            id
            title
            slug {
                current
            }
            excerpt
            mainImage {
                ...ImageWithPreview
            }
        }
    }
}
`;